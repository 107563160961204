(function ($) {
  'use strict';
  $(document).ready(function () {
    // gender mandates list and single node: toggle body and summary
    var gm_button = $('a.gm-toggle-button');
    $(gm_button).click(function (e) {
      e.preventDefault();
      var teaser = $(this).parents('.node-gender-mandate');
      $('.node-gender-mandate').not(teaser).removeClass('open-summary open-body');
      var button = $(this).hasClass('g-ref'); // boolean
      if (button) {
        teaser.removeClass('open-body');
        if (teaser.hasClass('open-summary')) {
          teaser.removeClass('open-summary');
        }
        else {
          teaser.addClass('open-summary');
        }
      }
      else {
        teaser.removeClass('open-summary');
        if (teaser.hasClass('open-body')) {
          teaser.removeClass('open-body');
        }
        else {
          teaser.addClass('open-body');
        }
      }
    });

    // flickity - frontpage
    var carousel = $('.view-id-teaser_view_homepage .view-content').flickity({
      // options
      cellAlign: 'left',
      contain: true,
      wrapAround: true,
      autoPlay: 3000,
      prevNextButtons: false,
      pageDots: true
    });

    // Add a play/pause button to front page slides.
    var flkty = carousel.data("flickity");
    var playPause = carousel.parent().find(".play-pause");
    playPause.html("Pause");
    playPause.addClass('unpaused');
    playPause.on("click", function () {
      if (flkty.player.state === "playing") {
          carousel.flickity("stopPlayer");

          // logic to show play icon here
          playPause.html("Play");
          playPause.addClass('paused');
          playPause.removeClass('unpaused');

      } else {
          carousel.flickity("playPlayer");

          // logic to show pause icon here
          playPause.html("Pause");
          playPause.addClass('unpaused');
          playPause.removeClass('paused');

      }
    });

    // chosen
    $('select').not('select#edit-submitted-type-or-select-country, select#edit-submitted-date-of-publication-year, select#edit-field-theme-tid, select#edit-field-tags-tid, select#edit-field-reference-to-women-gender-value').chosen();


    // multiple select ( select -> checkboxes )
    // $('select#edit-submitted-type-or-select-country').multipleSelect({
    //   isOpen: true,
    //   keepOpen: true,
    //   selectAll: false,
    //   countSelected: false,
    //   multiple: true,
    //   multipleWidth: 200,
    //   maxHeight: 250
    // });

    $('select#edit-field-tags-tid').multipleSelect({
      isOpen: true,
      keepOpen: true,
      selectAll: false,
      countSelected: false,
      multiple: false,
      multipleWidth: 200
    });

    $('select#edit-field-theme-tid').multipleSelect({
      isOpen: true,
      keepOpen: true,
      selectAll: false,
      countSelected: false,
      multiple: true,
      multipleWidth: 220,
      width: '500px',
      maxHeight: 250
    });


    init_headersearch();

    // mobile menu
    $('.menu-trigger').click(function () {
      $('body').removeClass('search-header-open');
      // $('body').removeClass('lang-switch-open');
      $('body').toggleClass('mobile-menu-click');
    });


    // close language and search overlays by clicking on node
    $('.views-row-5 > .node.node-section-header .field-name-field-title-multilanguage').click(function () {
      $('body').removeClass('search-header-open');
      // $('body').toggleClass('mobile-menu-click');
    });


    // language switcher
    var lang_menu_links = $("#block-locale-language-content .content").html();
    $('.mlid-350, .mlid-736, .mlid-765').html(lang_menu_links);

    $('.mlid-350 ul li > a.active, .mlid-736 ul li > a.active, .mlid-765 ul li > a.active').click(
      function (e) {
        e.preventDefault();
        $(this).parent().parent().parent().toggleClass('collapsed');
        $(this).parent().parent().parent().toggleClass('expanded');
      });

    // search header
    init_headersearch();
    $('.mlid-354 > a, .mlid-740 > a, .mlid-769 > a').click(
      function (e) {
        e.preventDefault();
        $('body').removeClass('mobile-menu-click');
        $('body').toggleClass('search-header-open');

      });

    //display introduction text/block on gender mandate page only once for 24hours.
    var date = new Date();
    $('#edit-submit-gender-mandates-view').on('click', function () {
      date.setTime(date.getTime() + (1 * 24 * 60 * 60 * 1000));
      $.cookie("gm-intro-text", 0, {expires: date});
      //console.log('cookie set');
    });
    if ($.cookie("gm-intro-text") == 0) {
      $('.page-gender-mandates #block-block-7').addClass('hide');
    }


    function init_headersearch() {

      $('#header-search-form').on('submit',
        function () {
          var searchterm = $('#header-search-term').val();
          if (searchterm.length > 2) {
            var serialized = $(this).serialize();
            serialized = serialized.replace('header-search-term=', 'search_api_views_fulltext=');
            window.location = '/search?' + serialized;
          }
        });
    }

    // If the fielset header value equals "New or Updated NDC", then change it to the value in field_updated_or_new_ndc
    var new_or_updated_ndc_header_text = $('div.field-name-field-updated-or-new-ndc div.field-item').hide().html();
    if ($('#ui-accordion-1-header-0 a').html() == 'New or Updated NDC') {
      $('#ui-accordion-1-header-0 a').html(new_or_updated_ndc_header_text);
    }
    if ($('#ui-accordion-2-header-0 a').html() == 'New or Updated NDC') {
      $('#ui-accordion-2-header-0 a').html(new_or_updated_ndc_header_text);
    }

    // Swap field group label with translated version.
    let gender_ndc_summary_label = $('div.gender-ndc-summary-label input').val();
    if (gender_ndc_summary_label) {
      $('table.group_ndc_summary caption').html(gender_ndc_summary_label);
    }

    // Swap field group label with translated version.
    let gender_ndc_summary2_label = $('div.gender-ndc-summary2-label input').val();
    if (gender_ndc_summary2_label) {
      $('table.group_ndc_summary2 caption').html(gender_ndc_summary2_label);
    }

  });

  // 2  - add selected class to custom div depending on drupal select
  var drupal_select_selected = $('select#edit-field-reference-to-women-gender-value').val();
  if (drupal_select_selected == 1) {
    $('.r1').addClass('selected');
  }
  else if (drupal_select_selected == 2) {
    $('.r2').addClass('selected');
  }

  $('#edit-jump').chosen();


  // gender mandates view exposed filter: gender reference yes/no -> select to checkboxes

  Drupal.behaviors.countryProfiles = {
    attach: function (context, settings) {

      // 1  - change drupal select by clicking on custom div
      $('.gender-reference-radio').click(function () {
        var radio_val = $(this).data('value');
        $('#edit-field-reference-to-women-gender-value').val(radio_val).change();
      });

      // 2  - add selected class to custom div depending on drupal select
      var drupal_select_selected = $('select#edit-field-reference-to-women-gender-value').val();
      if (drupal_select_selected == 1) {
        $('.r1').addClass('selected');
      }
      else if (drupal_select_selected == 2) {
        $('.r2').addClass('selected');
      }

      $('#edit-jump').chosen();

    }
  };

  //hackto open resource-files in new window
  $(document).ready(function() {
    $('.field-name-field-resource-file a').attr({ target: "_blank" });

    // ---- manipulating menu link texts:
    //gender mandate - en
    addlinebreaktoitemafterwordnumber($('.mlid-355 a span.main-menu-icon'), 2);
    //gender mandate - fr
    addlinebreaktoitemafterwordnumber($('.mlid-741 a span.main-menu-icon'), 4);
    //gender mandate - es
    addlinebreaktoitemafterwordnumber($('.mlid-770 a span.main-menu-icon'), 3);

  });

  function addlinebreaktoitemafterwordnumber($item, $wordnumber) {
    if ($item.length){
      var original_text = $item.html();
      var words = original_text.split(" ");
      var new_text = "";
      var i;
      for (i = 0; i < words.length; i++){
        new_text += words[i];
        if (i == ($wordnumber-1)) {new_text += "<br>";}
        new_text += " ";
      }

      console.log('Old: ', original_text);
      console.log('new: ', new_text);

      $item.html(new_text);
    }
    }

    // $('.language-switcher-locale-url a').click

}(jQuery));

